/* globals $ */
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customer"
export default class extends Controller {
  connect() {
  }

  submitContact(event) {
    let button = event.target //document.getElementById('contact_update_button')
    button.classList.add('disabled')
    button.innerHTML = '<span class="glyphicon glyphicon-refresh spinning"></span> Updating...'
  }

  openContactModal(event) {
    $('#customer_contact_modal').modal('show')
  }

  closeContactModal(event) {
    if (event.detail.success) {
      $('#customer_contact_modal').modal('hide')
    }
  }
}

