/* globals $ */
document.addEventListener('triggerJQuery', function () {
  if (!($('.edi_transactions').length > 0)) { return }

  $('select#ebt_type').change(function () {
    const codeList = $('select[id=code]')

    codeList.empty()

    var codeType = $(this).val().toLowerCase()
    var codes = $('select#code').data(`${codeType}-codes`)

    if (codes) {
      codeList.append('<option value="">Please select</option>')
      codes.map(code => codeList.append($('<option></option>').attr('value', code).text(code)))
    } else {
      codeList.append('<option value="">Code not required</option>')
    }
  })

  // refresh code select list
  $('select#ebt_type').change()
})
