/* globals $ */

document.addEventListener('triggerJQuery', function () {
  $('#pool_charge_price_index_id').change(function () {
    if ($(this).val()) {
      $('#pool_charge_amount_wrapper').hide()
      $('#pool_charge_units_wrapper').hide()
      $('#pool_charge_amount').val('')
      $('#pool_charge_units').val('')
    } else {
      $('#pool_charge_amount_wrapper').show()
      $('#pool_charge_units_wrapper').show()
    }
  })

  $('#show-charge-form-link').click(function () {
    $(this).hide()
    $('#charge-form').show()
    $('select#pool_charge_charge_type').focus()
    return false
  })

  return $('#hide-charge-form-link').click(function () {
    $('#charge-form').hide()
    $('#show-charge-form-link').show()
    return false
  })
})
