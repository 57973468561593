/* globals $ */
import { get } from "@rails/request.js"

// chevron collapse icon toggle
$(document).on('click', '.chevron-collapse', function (event) {
  return $(this).find('span').toggleClass('glyphicon-chevron-right glyphicon-chevron-down')
})

// mail delivery modal
$(document).on('click', '.mail_delivery_link', function () {
  // const url = $(this).attr('href')
  // get(url, { responseKind: 'turbo-stream' })
  $('#mail_delivery_modal').modal('show')
  // return false
})

// document.addEventListener("click", event => {
//   const element = event.target.closest("[data-confirm]")

//   if (element && !confirm(element.dataset.confirm)) {
//     event.preventDefault()
//   }
// })

document.addEventListener('triggerJQuery', function () {
  // handle sync button updates
  $('.link-sync').bind('click', function () {
    $(this).addClass('disabled')
    $(this).html('<span class="glyphicon glyphicon-refresh spinning"></span> Syncing...')
  })

  $('#search_customers_button').bind('click', function () {
    $(this).addClass('disabled')
    $(this).html('<span class="glyphicon glyphicon-refresh spinning"></span> Searching...')
  })

  // moved to init.js
  // $('.datetimepicker').datetimepicker({
  //   format: 'MM/DD/YYYY',
  //   useCurrent: false
  // })

  $('a#export_button').click(function () {
    if (($(this).data('message') == null) || window.confirm($(this).data('message'))) {

      const recordCount = $(this).data('count') || 0
      if (recordCount > 5000) {
        window.alert('Please filter selection to fewer than 5000 records before exporting')
        return false
      }

      $('[data-toggle="dropdown"]').parent().removeClass('open') // close any open dropdown menus
      window.scrollTo(0, 0)
      const alertMessage = "<div class='alert alert-info fade in'><i class='fa fa-cog fa-spin fa-lg fa-fw'></i>Processing your export request. You will be notified when your export is complete.</div>"
      $('.container').prepend(alertMessage)
      return true
    } else {
      return false
    }
  })

  $('a#export_products_button').click(function () {
    $('[data-toggle="dropdown"]').parent().removeClass('open') // close any open dropdown menus
    window.scrollTo(0, 0)
    const alertMessage = "<div class='alert alert-info fade in'><i class='fa fa-info-circle fa-lg fa-fw'></i>Processing your TPV & vendor product export request. Product export emails will be sent if updated products exist.</div>"
    $('.container').prepend(alertMessage)
  })
})
