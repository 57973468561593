/* globals $ Turbolinks */

document.addEventListener('triggerJQuery', function () {
  if (!($('.competitor_prices').length > 0)) { return }

  $('#show_competitor_price_filters').click(function () {
    $('#price_filters').slideDown('fast')
    $(this).hide()
    return false
  })
})
