import { Controller } from "@hotwired/stimulus"
import { get, post } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "addContactButton"]
  static values = { disableCustomerLookup: Boolean }

  connect() {
    // console.log("enrollments stimulus controller connected")
    this.showAddContactButton() // update visibility of AddContactButton
  }

  disconnect() {
  }

  showAddContactButton() {
    if ($('.contact-form:visible').length >= 2) {
      $(this.addContactButtonTarget).hide()
    } else {
      $(this.addContactButtonTarget).show()
    }
  }

  addContact(event) {
    const content = $(event.currentTarget).data('content')
    const newId = (new Date()).getTime()
    const regexp = new RegExp('new_contacts', 'g')
    $(event.currentTarget).parent().before(content.replace(regexp, newId))
    this.showAddContactButton()
    App.applyMasks()

    event.preventDefault()
  }

  removeContact(event) {
    $(event.currentTarget).prev('input[type=hidden]').val('1')
    $(event.currentTarget).closest('.fields').hide()
    this.showAddContactButton()

    event.preventDefault()
  }

  addLocation(event) {
    const content = $(event.currentTarget).data('content')
    const newId = (new Date()).getTime()
    const regexp = new RegExp('new_service_locations', 'g')
    $(event.currentTarget).parent().before(content.replace(regexp, newId))

    event.preventDefault()
  }

  removeLocation(event) {
    $(event.currentTarget).prev('input[type=hidden]').val('1')
    $(event.currentTarget).closest('.fields').hide()

    event.preventDefault()
  }

  copyServiceAddress(event) {
    const street = $('#enrollment_service_locations_attributes_0_address_street').val()
    const city = $('#enrollment_service_locations_attributes_0_address_city').val()
    const state = $('#enrollment_service_locations_attributes_0_address_state').val()
    const zip = $('#enrollment_service_locations_attributes_0_address_zip').val()

    const row = $(event.currentTarget).closest('.row')
    row.find("input[id$='mailing_street']").val(street)
    row.find("input[id$='mailing_city']").val(city)
    row.find("select[id$='mailing_state']").val(state)
    row.find("input[id$='mailing_zip']").val(zip)

    event.preventDefault()
  }

  async startTpvRequest(event) {
    $('#tpv_error_message').text('').hide()
    $('#enrollment_tpv_confirmation_id').val('')
    $('#submit_tpv_button').addClass('disabled')
    $('#submit_tpv_button').html('<span class="glyphicon glyphicon-refresh spinning"></span> Submitting...')
    let locale = $('select#enrollment_locale').val()

    event.preventDefault()

    const enrollment_id = $(event.currentTarget).data('enrollment-id')
    const response = await post(`/enrollments/${enrollment_id}/tpv?locale=${locale}`, { responseKind: 'json' })
    const data = await response.json
    if (response.ok) {
      $('#enrollment_tpv_confirmation_id').val(data.confirmation_id)

      // highlight effect with restart ability (remove node and add back)
      $('#enrollment_tpv_confirmation_id').addClass('highlight-fade')
      const elm = $('#enrollment_tpv_confirmation_id')[0]
      const newone = elm.cloneNode(true)
      elm.parentNode.replaceChild(newone, elm)
    } else {
      const errorMessage = `<strong><i class='fa fa-exclamation-circle'></i> TPV Error:</strong> ${data.error}`
      $('#tpv_error_message').html(errorMessage).slideDown()
    }

    $('#submit_tpv_button').removeClass('disabled')
    $('#submit_tpv_button').html('Submit TPV')
    $('#submit_tpv_button').blur()
  }

  showPreviousFields(event) {
    $('#current_fields').hide()
    $('#previous_fields').slideDown()

    event.preventDefault()
  }

  validateAccountNumber(event) {
    if ($(event.currentTarget).attr('readonly') == 'readonly') { return }

    let regex = new RegExp($(event.currentTarget).data('regex'), 'i')
    let valid = regex.test($(event.currentTarget).val())
    let icon = $(event.currentTarget).next('span.glyphicon')

    if (valid) {
      icon.removeClass('glyphicon-remove')
      icon.addClass('glyphicon-ok')
      $(event.currentTarget).parent().addClass('has-success')
      $(event.currentTarget).parent().removeClass('has-error')
    } else {
      icon.removeClass('glyphicon-ok')
      icon.addClass('glyphicon-remove')
      $(event.currentTarget).parent().removeClass('has-success')
      $(event.currentTarget).parent().addClass('has-error')
    }

  }

  async lookupCustomer(event) {
    // do not lookup customer if account number is not validated
    if (!$(event.currentTarget).parent().hasClass('has-success')) { return }
    // do not lookup customer if explicitly disabled (brokers)
    if (this.disableCustomerLookupValue) { return }

    let params = new URLSearchParams()
    params.append('utility_duns', $(event.currentTarget).data('utility-duns'))
    params.append('utility_account', $(event.currentTarget).val())

    const response = await get(`/customers/search?${params}`, { responseKind: 'turbo-stream' })
    if (response.ok) {
      $('#duplicate_customer_modal').modal({show: true, backdrop: 'static'})
    }
  }

  holdEnrollment(event) {
    $('form.edit_enrollment').append('<input type="hidden" name="commit" value="Hold Enrollment"/>')
    $('form.edit_enrollment').submit()

    event.preventDefault()
  }
}

