/* globals $ */

document.addEventListener('triggerJQuery', function () {
  if (!($('.quote_prices, .cost_builds').length > 0)) { return }

  $('select#quote_price_price_type').change(function () {
    if ($(this).val() === 'N') {
      $('select#quote_price_price_index_id').show()
    } else {
      $('select#quote_price_price_index_id').hide()
    }
  })

  // to support cost build calculator
  return $('select#price_type').change(function () {
    if ($(this).val() === 'N') {
      $('select#price_index_id').show()
    } else {
      $('select#price_index_id').hide()
    }
  })
})
