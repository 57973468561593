import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "productResults" ]

  connect() {
    // console.log("enrollment product controller")
    this.find_utilities()
  }

  productResultsTargetConnected(element) {
    $('[data-toggle="tooltip"]').tooltip()
  }

  find_utilities () {
    const zip = $('input[id=zip]').val()
    const commodity = 'G'

    if (zip.length === 5) {
      if ($('#utilities').data('query') !== (zip + commodity)) {
        $('#utilities').data('query', zip + commodity)
        const callback = response => this.populate_utility_list(response)
        $.ajax({ url: '/utility_locations/find_utilities', data: { zip, commodity }, success: callback, async: true, cache: false, dataType: 'json' })
      }
    } else {
      $('#utilities').data('query', '')
      this.populate_utility_list([])
    }
  }

  populate_utility_list (utilities) {
    const utilityList = $('select[id=utility_id]')
    utilityList.empty()

    if (utilities.length === 0) {
      utilityList.append('<option value="">No matching utilities</option>')
    } else {
      utilityList.append('<option value="">Please select</option>')
    }

    utilities.map(utility => utilityList.append($('<option></option>').attr('value', utility.id).text(utility.name)))

    const selectedId = utilities.length === 1 ? utilities[0].id : undefined
    utilityList.val(selectedId).val() || utilityList.val('')
    return $('select[id=utility_id]').change()
  }

  submit(event) {
    if (!$('select[id=utility_id]').val()) {
      event.preventDefault()
    }
  }

  clearProductResults() {
    $('#product_results').empty()
  }

}
