/* globals $ Turbo */
import consumer from './consumer'

consumer.subscriptions.create('NotificationsChannel', {
  connected () {
    // Called when the subscription is ready for use on the server
  },

  disconnected () {
    // Called when the subscription has been terminated by the server
  },

  received (data) {
    // Called when there's incoming data on the websocket for this channel
    $('.alert').hide()

    // if this is import file notification, refresh the import files page if we are still on it
    if ((data.import_file_id != null) && /import_files/i.test(window.location.toString())) {
      Turbo.visit(window.location.toString())
    } else if (data.error != null) {
      const alertMessage = "<div class='alert alert-danger fade in'><i class='fa fa-exclamation-circle fa-lg fa-fw'></i>" + data.message + '</div>'
      $('.container').prepend(alertMessage)
    } else {
      const alertMessage = "<div class='alert alert-success fade in'><i class='fa fa-check-circle fa-lg fa-fw'></i>" + data.message + '</div>'
      $('.container').prepend(alertMessage)
    }
  }
})
