import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="utility-select"
export default class extends Controller {
  static values = {
    childController: String
  }

  connect() {
  }

  change(event) {
    let brokerId = event.target.selectedOptions[0].value
    let path = window.location.href.split('?')[0]

    if (brokerId) {
      Turbo.visit(`${path}?broker_id=${brokerId}`)
    } else {
      Turbo.visit(`${path}`)
    }
  }

}
