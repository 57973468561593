import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="utility-select"
export default class extends Controller {
  static values = {
    childController: String
  }

  connect() {
  }

  change(event) {
    let utilityId = event.target.selectedOptions[0].value

    if (utilityId) {
      Turbo.visit(`/utilities/${utilityId}/${this.childControllerValue}${window.location.search}`)
    } else {
      Turbo.visit(`/${this.childControllerValue}${window.location.search}`)
    }
  }

}
