/* globals $ */

document.addEventListener('triggerJQuery', function () {
  if (!($('.campaign_contacts, .customers').length > 0)) { return }

  $('#show_new_contact input:checkbox').change(function () {
    if ($(this)[0].checked) {
      document.getElementById('old_contact_section').classList.add('old-contact')
      $('#new_contact_section').slideDown('fast')
    } else {
      $('#new_contact_section').slideUp('fast')
      document.getElementById('old_contact_section').classList.remove('old-contact')
    }
  })

  $('form#customer-add-campaign').submit(function (e) {
    $(this).attr("action", `/campaigns/${$("select#campaign_id").val()}/contacts/new`)
    // e.preventDefault()
  })

})
