/* globals $ */

document.addEventListener('triggerJQuery', function () {
  if (!($('.utilities').length > 0)) { return }

  $('#utility_units').change(function () {
    $("label[for='utility_units_nexant_conversion']").text(`Conversion (from ${$(this).val()})`)
  })

  $('#utility_meter_number_required').change(function () {
    if ($(this).prop('checked')) {
      $('#meter_number_fields').show()
    } else {
      $('#meter_number_fields').hide()
      $('#utility_meter_number_regex').val('')
      $('#utility_meter_number_hint').val('')
    }
  })
})
