import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // console.log("legacy controller connected")
    App.init()

    const event = new Event('triggerJQuery')
    document.dispatchEvent(event)
  }
}
