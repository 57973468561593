document.addEventListener('triggerJQuery', function () {
  if (!($('.import_files, .export_files').length > 0)) { return }

  $('#show_file_filters').click(function () {
    $('#file_filters').slideDown('fast')
    $(this).hide()
    $('#import_file_form').hide()
    return false
  })
})
