/* globals $ */

document.addEventListener('triggerJQuery', function () {
  // This may be called from multiple controllers
  $(document).on('change', "input[name='call_session[outbound]']", function () {
    if ($(this).val() === '1') {
      $('#inbound_phone_field').hide()
      return $('#call_session_inbound_phone').val('')
    } else {
      return $('#inbound_phone_field').show()
    }
  })

  if (!($('.call_sessions, .customer_services').length > 0)) { return }

  $('#show_call_filters').click(function () {
    $('#call_filters').slideDown('fast')
    $(this).hide()
    return false
  })
})
