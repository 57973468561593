/* globals $ */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    // console.log('industrial customer controller connected')
  }

  disconnect () {
  }

  addContact (event) {
    if (document.getElementById('contact-1').style.display === 'none') {
      $('#contact-1').show()
      $('#remove-contact-button').show()
    } else if (document.getElementById('contact-2').style.display === 'none') {
      $('#contact-2').show()
    } else if (document.getElementById('contact-3').style.display === 'none') {
      $('#contact-3').show()
      $('#add-contact-button').hide()
    }

    event.preventDefault()
  }

  removeContact (event) {
    if (document.getElementById('contact-3').style.display !== 'none') {
      $('#contact-3').hide()
      this.clearContactFields(3)
      $('#add-contact-button').show()
    } else if (document.getElementById('contact-2').style.display !== 'none') {
      $('#contact-2').hide()
      this.clearContactFields(2)
    } else if (document.getElementById('contact-1').style.display !== 'none') {
      $('#contact-1').hide()
      this.clearContactFields(1)
      $('#remove-contact-button').hide()
    }

    event.preventDefault()
  }

  changeReason (event) {
    const reason = $(event.currentTarget).val()
    let options = []
    const statusList = $('select[id=industrial_customer_contact_status]')
    statusList.empty()

    if (reason.match(/billing/i) !== null) {
      options = statusList.data('billing')
    } else if (reason.match(/contact/i) !== null) {
      options = statusList.data('contact')
    } else if (reason.match(/other/i) !== null) {
      options = statusList.data('other')
    }

    statusList.append('<option value="">Please select</option>')
    options.map(option => statusList.append($('<option></option>').attr('value', option).text(option)))
  }

  clearContactFields (index) {
    $('#industrial_customer_contact' + index + '_type').val('')
    $('#industrial_customer_contact' + index + '_first_name').val('')
    $('#industrial_customer_contact' + index + '_last_name').val('')
    $('#industrial_customer_contact' + index + '_phone').val('')
    $('#industrial_customer_contact' + index + '_email').val('')
  }
}
