/* globals $ Turbo */

document.addEventListener('triggerJQuery', function () {
  $('#pool_report_utility_select select#utility_id').change(function () {
    if ($(this).val()) {
      Turbo.visit(`/reports/price_pool_report?utility_id=${$(this).val()}`)
    } else {
      Turbo.visit('/reports/price_pool_report')
    }
  })

  $('#missing_price_report_utility_select select#utility_id').change(function () {
    if ($(this).val()) {
      Turbo.visit(`/reports/missing_prices_report?utility_id=${$(this).val()}`)
    } else {
      Turbo.visit('/reports/missing_prices_report')
    }
  })

  $('#comparison_report_utility_select select#utility_id').change(function () {
    if ($(this).val()) {
      Turbo.visit(`/reports/comparison_prices?utility_id=${$(this).val()}`)
    } else {
      Turbo.visit('/reports/comparison_prices')
    }
  })
})
