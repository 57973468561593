import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  changeLocale (event) {
    let selectedLocale = event.target.value

    let radios = document.querySelectorAll('input[type="radio"][name^="locale"]')
    for (let el of radios) {
      el.checked = (el.value == selectedLocale)
    }

    for (let el of document.querySelectorAll('.localeEnglish')) {
      el.style.display = (selectedLocale == 'English' ? 'block' : 'none')
    }

    for (let el of document.querySelectorAll('.localeSpanish')) {
      el.style.display = (selectedLocale == 'Spanish' ? 'block' : 'none')
    }

  }

}
