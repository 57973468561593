/* globals $ */

document.addEventListener('triggerJQuery', function () {
  if (!($('.customer_leads').length > 0)) { return }

  return $('#show_lead_filters').click(function () {
    $('#lead_filters').slideDown('fast')
    $(this).hide()
    return false
  })
})
