/* globals $ Turbo App */
import NexantSyncPoller from './nexant_sync'
import { get } from "@rails/request.js"

App.Product = {

  generate_product_code () {
    const params = $('form.new_product, form.edit_product').serialize()
    const utilityId = $('form.new_product, form.edit_product').data('utility-id')
    const callback = function (response) {
      const newCode = response.product_code === null ? '' : response.product_code
      if ($('#product_code').val() !== newCode) {
        $('#product_code').val(response.product_code)
      }
    }
    $.get(`/utilities/${utilityId}/products/product_code`, params, callback, 'json')
  },

  calculate_product_cost () {
    $('#product_current_cost, #product_current_margin').val('')
    if (!$('#product_price_pool_id').val() || !$('#product_cost_build_id').val()) { return }

    const params = $('form.new_product, form.edit_product').serialize()
    const utilityId = $('form.new_product, form.edit_product').data('utility-id')
    const callback = function (response) {
      const cost = response.product_cost
      const margin = response.product_margin
      $('#product_current_cost').val(cost)
      $('#product_current_margin').val(margin)
    }
    $.get(`/utilities/${utilityId}/products/product_cost`, params, callback, 'json')
  },

  // refresh_brokers(channel) {
  //   const utilityId = $('form').data('utility-id')

  //   if (channel) {
  //     $.get(`/utilities/${utilityId}/products/brokers`, `channel=${channel}`, null, "script")
  //   } else {
  //     $("select#product_product_channel").html("<option>Please select</option>")
  //   }
  // }
}

$(document).on('click', '.select_pool_link', function () {
  $('#product_price_pool_id').val($(this).data('id')).trigger('change')
  $('#product_price_pool_name').val($(this).data('name'))
  $('#product_pools_modal').modal('hide')
  return false
})

document.addEventListener('triggerJQuery', function () {
  if (!($('.products').length > 0)) { return }

  $('#show_product_filters').click(function () {
    $('#product_filters').slideDown('fast')
    $(this).hide()
    return false
  })

  $('#product_pool_link').click(function () {
    const pricePoolId = $('#product_price_pool_id').val()
    if (pricePoolId) { Turbo.visit(`/price_pools/${$('#product_price_pool_id').val()}`) }
    return false
  })

  // $('select#product_product_channel').change(function () {
  //   App.Product.refresh_brokers($(this).val())
  // })

  $('#product_term_end_date').bind('dp.change', function () {
    if ($(this).val()) {
      return $('#product_bill_end_date').val($(this).val())
    }
  })

  $('#product_term_type').change(function () {
    if ($(this).val() === 'T') {
      $('#product_term_end_date_control').hide()
      $('#product_term_months_control').show()
      $('#rollover_product_control').show()
    } else {
      $('#product_term_months_control').hide()
      $('#product_term_months').val('')
    }

    if ($(this).val() === 'D') {
      $('#product_term_months_control').hide()
      $('#product_term_end_date_control').show()
      $('#rollover_product_control').show()
    } else {
      $('#product_term_end_date_control').hide()
      $('#product_term_end_date').val('')
    }

    if ($(this).val() === 'M') {
      $('#rollover_product_control').hide()
      return $('#product_rollover_product_id').val('')
    }
  })

  $('#product_price_type').change(() => $('#product_price_pool_id, #product_price_pool_name').val(''))

  $('#product_price_type, #product_offer_type, #product_term_type, #product_product_segment, #product_term_end_date, #product_term_months, #product_price_pool_id').change(() => App.Product.generate_product_code())

  $('#product_cost_build_id, #product_price_type, #product_term_type, #product_term_start_date, #product_term_end_date, #product_term_months, #product_price_pool_id').change(function () {
    if ($('#product_current_cost').length) {
      App.Product.calculate_product_cost()
    }
  })

  $('#show_pools_link').click(function () {
    const utilityId = $('form.new_product, form.edit_product').data('utility-id')
    const priceType = $('#product_price_type').val()
    $('#product_pools_modal').modal('show')
    $('#pool_results').html("<div style='font-size:1.4em text-align:center'><span class='glyphicon glyphicon-refresh spinning'></span> Loading Price Pools</div>")
    get(`/utilities/${utilityId}/products/price_pools?price_type=${priceType}`, { responseKind: 'turbo-stream' })

    return false
  })

  $('#batch_form input[name="product_id[]"]').click(function () {
    if ($('#batch_form input[name="product_id[]"]:checked').length > 0) {
      $('#batch-update-button').show()
    } else {
      $('#batch-update-button').hide()
    }
  })

  $('#batch_select_all').click(function () {
    if ($('#batch_form input[name="product_id[]"]:checked').length > 0) {
      $('#batch_form input[name="product_id[]"]').prop('checked', false)
      $('#batch-update-button').hide()
    } else {
      $('#batch_form input[name="product_id[]"]').prop('checked', true)
      $('#batch-update-button').show()
    }
    return false
  })

  $('#batch_suspend, #batch_activate, #batch_inactivate').click(function () {
    $('input[name="batch_action"]').val($(this).attr('id'))
    if (window.confirm('Batch update selected products?')) {
      $('form#batch_form').submit()
    }
    return false
  })

  $('a.link-sync').bind('click', function () {
    $('#product_synced_at').val('')
    const productId = $(this).data('product-id')
    new NexantSyncPoller($('#product_synced_at'), `/products/${productId}/last_sync`).poll()
  })
})
