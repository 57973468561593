/* globals $ Turbo */
import { get } from "@rails/request.js"

$(document).on('change', '#cost_price_index_id', function (event) {
  if ($(this).val()) {
    $('.cost_amount_wrapper').hide()
    $('.cost_units_wrapper').hide()
    $('#cost_amount').val('')
    $('#cost_units').val('')
  } else {
    $('.cost_amount_wrapper').show()
    $('.cost_units_wrapper').show()
  }
})

document.addEventListener('triggerJQuery', function () {
  if (!($('.cost_builds, .costs, .product_margins').length > 0)) { return }

  $('#show-cost-form-link').click(function () {
    $('#cost-index-buttons').hide()
    $('form#new_cost select#cost_price_index_id').change()
    $('#new-cost-form').show()
    $('input#cost_name').focus()
    return false
  })

  $(document).on('click', '#hide-cost-form-link', function () {
    $('#new-cost-form').hide()
    $('#edit-cost-form').hide()
    $('#cost-index-buttons').show()
    return false
  })

  $('.edit-cost-link').click(function () {
    $('#cost-index-buttons').hide()
    $('#new-cost-form').hide()
    $('#edit-cost-form').show()

    const url = $(this).attr('href')
    get(url, { responseKind: 'turbo-stream' })

    return false
  })

  $('#new-cost-build-popover').popover({
    html: true,
    container: 'body',
    sanitize: false,
    content () {
      return $('#new-cost-build-form').html()
    }
  })
})
