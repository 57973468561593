/* globals $ */
import { Controller } from "@hotwired/stimulus"
import { get, put } from "@rails/request.js"
// let debounce = require("lodash/debounce")

export default class extends Controller {
  static targets = [ "brokerFee", "margin", "price", "poolPrice" ]
  static values = {
    quoteId: Number,
    quotePriceId: Number,
  }

  // initialize(){
  //   this.updatePrice = debounce(this.updatePrice, 500).bind(this)
  // }

  connect() {
  }

  updatePrice() {
    get(`/quotes/${this.quoteIdValue}/prices/${this.quotePriceIdValue}/calculate_price?broker_fee=${this.brokerFeeTarget.value}&margin=${this.marginTarget.value}`, { responseKind: "turbo-stream" })
  }

  priceTargetConnected(element) {
    this.priceTarget.style.fontWeight = 'bold'

    setTimeout(() => {
      this.priceTarget.style.fontWeight = 'normal'
    }, 250)
  }

  poolPriceTargetConnected(element) {
    this.poolPriceTarget.style.fontWeight = 'bold'

    setTimeout(() => {
      this.poolPriceTarget.style.fontWeight = 'normal'
    }, 250)
  }

}
