import { Controller } from "@hotwired/stimulus"
import Trix from 'trix'

// Connects to data-controller="trix-font-size"
export default class extends Controller {

  connect() {
    Trix.config.textAttributes.fontSize = {
      style: { "font-size": "0.7em" },
      inheritable: true,
      parser: function(element) {
        return element.style.fontSize.match(/0.7em/)
      }
    }

    Trix.config.textAttributes.red = {
      style: { color: "red" },
      parser: function(element) {
        return element.style.color === "red"
      },
      inheritable: true
     }

    addEventListener("trix-initialize", function(event) {
      const groupElement = event.target.toolbarElement.querySelector('[data-trix-button-group="text-tools"]')
      var buttonHTML = '<button type="button" class="trix-button" data-trix-attribute="red">RED</button>'
      buttonHTML += '<button type="button" class="trix-button" data-trix-attribute="fontSize" title="fontSize">small</button>'
      groupElement.insertAdjacentHTML('beforeend', buttonHTML)
    }, { once: true })

  }
}
