/* globals $ Turbo */
import { get } from "@rails/request.js"

document.addEventListener('triggerJQuery', function () {
  if (!($('.settings').length > 0)) { return }

  $('#show-setting-form-link').click(function () {
    $('#settings-index-buttons').hide()
    $('#new-setting-form').show()
    window.scrollTo(0, document.body.scrollHeight)
    return false
  })

  $(document).on('click', '#hide-setting-form-link', function () {
    $('#new-setting-form').hide()
    $('#edit-setting-form').hide()
    $('#settings-index-buttons').show()
    return false
  })

  $('#form_setting_type select#setting_type').change(function () {
    return Turbo.visit(`/settings?setting_type=${$(this).val()}`)
  })

  $('.new-static-setting').click(function () {
    $('#edit-setting-form').hide()
    $('#settings-index-buttons').hide()
    $('#new-setting-form').show()
    $('input#setting_name').val($(this).data('setting-name'))
    window.scrollTo(0, document.body.scrollHeight)
    return false
  })

  $('.edit-setting-link').click(function () {
    $('#settings-index-buttons').hide();
    $('#new-setting-form').hide();
    $('#edit-setting-form').show();

    const url = $(this).attr('href')
    get(url, { responseKind: 'turbo-stream' })

    window.scrollTo(0,document.body.scrollHeight);

    return false
  })

})
