/* globals $ */

document.addEventListener('triggerJQuery', function () {
  if (!($('.cost_profiles').length > 0)) { return }

  return $("input[type=text][name^='cost_profile[cost_profile_values_attributes']").change(function () {
    const profile = []
    $('input[type=text]').each(function () {
      profile.push(parseInt($(this).val()))
    })
    window.chart.series[0].setData(profile)
  })
})
