/* globals $ */

document.addEventListener('triggerJQuery', function () {
  if (!($('.brokers').length > 0)) { return }

  $('#show_broker_filters').click(function () {
    $('#broker_filters').slideDown('fast')
    $(this).hide()
    return false
  })
})
