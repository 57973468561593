/* globals $ Turbo */

export default class NexantSyncPoller {
  constructor(sync_field, poll_url) {
    this.max_poll_count = 10
    this.poll_count = 0
    this.sync_field = sync_field
    this.poll_url = poll_url
  }

  poll() {
    this.poll_count += 1
    setTimeout(this.request.bind(this), 2000)
  }

  failure(error) {
    this.sync_field.val(error)
    $('.link-sync').removeClass('disabled')
    $('.link-sync').html('<span class="glyphicon glyphicon-cloud-upload"></span> Sync')
  }

  request() {
    return $.ajax({
      url: this.poll_url,
      context: this,
      success(data) {
        if (parseFloat(data) !== parseFloat(this.sync_field.data('synced-at'))) {
          Turbo.visit(window.location.href)
        } else if (this.poll_count >= this.max_poll_count) {
          this.failure('SYNC TIMEOUT')
        } else {
          this.poll()
        }
      },
      error() {
        this.failure('SYNC ERROR')
      }
    })
  }
}
