import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "duration" ]

  connect() {
    if (this.hasDurationTarget) {
      this.updateCallDuration()
      this.startRefreshing()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  updateCallDuration() {
    this.durationTarget.textContent = this.callDuration

    // 30 minute duration theshold
    if (this.elapsedSeconds > (60 * this.data.get('duration-timeout'))) {
      document.getElementById('call-navbar').classList.add('attention');
    }

  }

  startRefreshing()
  {
    this.refreshTimer = setInterval(() => this.updateCallDuration(), 1000);
  }

  stopRefreshing()
  {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  get elapsedSeconds() {
    return (new Date().getTime() / 1000) - this.data.get('start-time')
  }

  get callDuration() {
    const elapsed_seconds = this.elapsedSeconds
    const duration_minutes = Math.floor(elapsed_seconds / 60);
    const duration_seconds = Math.floor(elapsed_seconds - (duration_minutes * 60));
    return `${duration_minutes}m ${duration_seconds}s`
  }

  async showCallModal(event)
  {
    event.preventDefault()

    let params = new URLSearchParams()
    let customerMatcher = window.location.href.match(/\/customers\/(\d+)/)
    if (customerMatcher) {
      params.append('customer_id', customerMatcher[1])
    }

    const response = await get(`/call_sessions/new?${params}`, { responseKind: "turbo-stream" })
    if (response.ok) {
      $('#csr_call_modal').modal('show')
    }
  }
}
