import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-generator"
export default class extends Controller {
  static targets = [ "termEndDate", "termMonths", "internalMargin", "marginField", "brokerFeeField" ]

  connect() {
  }

  changeTermType (event) {
    let termType = event.target.selectedOptions[0].value

    document.getElementById("product_generator_term_end_date").value = ""
    document.getElementById("product_generator_term_months").value = ""

    if (termType == 'T') {
      this.termMonthsTarget.style.display = "block"
      this.termEndDateTarget.style.display = "none"
    } else if(termType == 'D') {
      this.termEndDateTarget.style.display = "block"
      this.termMonthsTarget.style.display = "none"
    } else if(termType == 'M') {
      this.termEndDateTarget.style.display = "none"
      this.termMonthsTarget.style.display = "none"
    }
  }

  showFilters (event) {
    document.getElementById("generator_filters").style.display = 'block'
    document.getElementById("show_generator_filters").style.display = 'none'
    event.preventDefault()
  }

  updateMargin (event) {
    this.internalMarginTarget.innerHTML = Number((Number(this.marginFieldTarget.value) - Number(this.brokerFeeFieldTarget.value)).toFixed(5))
  }

}
