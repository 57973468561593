import { Controller } from "@hotwired/stimulus"
import { get, put } from "@rails/request.js"
import sortable from 'html5sortable/dist/html5sortable.es.js'

export default class extends Controller {
  static targets = [ "item", "editButton", "newButton", "form" ]

  connect() {
    // console.log("legal terms connected")

    // sortable functionality
    sortable('.sortable', {
      // disableIEFix: true,
      placeholderClass: 'drag-placeholder',
      handle: 'div.legal-term-handle'
    })

    // after the order changes
    sortable('.sortable')[0].addEventListener('sortupdate', function (e) {
      this.storeNewPositions()
    }.bind(this)) // bind so we can reference stimulus controller method from event
  }

  disconnect() {
    // Doesn't look like we need to disconnect in this scenario
    // sortable('.sortable')[0].removeEventListener('sortupdate', this.boundSortUpdateEvent)
  }

  itemTargetConnected(element) {
    // this.sortElements(this.itemTargets)
  }

  itemTargetDisconnected(element) {
    // this.sortElements(this.itemTargets)
  }

  setPositions () {
    $('ul.sortable > li').each(function (i) {
      $(this).attr('data-pos', i + 1)
      $(this).find('span.term-priority').text(i + 1)
    })
  }

  storeNewPositions () {
    let updatedOrder = []
    let utilityId = $('#terms-conditions-list').data('utility-id')
    this.setPositions()

    $('ul.sortable > li').each(function (i) {
      updatedOrder.push({
        id: $(this).data('id'),
        position: i + 1
      })
    })

    put('/utilities/' + utilityId + '/legal_terms/sort', { body: { order: updatedOrder } })
  }

  hideForm (event) {
    this.newButtonTarget.style.display = "inline"
    this.formTarget.style.display = "none"

    event.preventDefault()
  }

  showForm (event) {
    this.newButtonTarget.style.display = "none"
    this.formTarget.style.display = "block"
    window.scrollTo(0, document.body.scrollHeight)

    event.preventDefault()

    const url = event.target.getAttribute('href')
    get(url, { responseKind: 'turbo-stream' })
  }
}
