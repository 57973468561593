/* globals $ Turbo App */
import NexantSyncPoller from './nexant_sync'

App.PricePool = {

  price_type_not_indexed () {
    $('#price-index-control').hide()
    $('select#price_pool_price_index_id').val('')
    $('label[for="price_pool_price_adder"]').text('Fixed price')
  },

  // Gets price index values to show on pool form popover
  update_price_index_table () {
    const price_index_name = $('#price_pool_price_index_id').find(':selected').text()
    const price_index_id = $('#price_pool_price_index_id').val()
    if (!price_index_id) { return }

    $.get(`/price_indexes/${price_index_id}/price_table.js`, response => $('#price-popover').popover({ title: price_index_name, html: true, content: response }).popover('show'))
  }
}

document.addEventListener('triggerJQuery', function () {
  if (!($('.price_pools').length > 0)) { return }

  // # live search for price pools
  // $("#pools_search input#filter").keyup (e) ->
  //   if $(this).data('filter') != $(this).val()
  //     App.delay (->
  //       utility_id = $('#pools_search select#utility_id').val()
  //       url = if utility_id
  //         "/utilities/#{utility_id}/price_pools"
  //       else
  //         "/price_pools"
  //       $.get url, { filter: $("#pools_search input#filter").val() }, null, "script"
  //     ), 300
  //     $(this).data('filter', $(this).val())
  //   return false

  $('a.link-sync').bind('click', function () {
    $('#price_pool_synced_at').val('')
    let poolId = $(this).data('pool-id')
    new NexantSyncPoller($('#price_pool_synced_at'), `/price_pools/${poolId}/last_sync`).poll()
  })

  $('#show_pool_filters').click(function () {
    $('#pool_filters').slideDown('fast')
    $(this).hide()
    return false
  })

  $('#price_pool_bill_method').change(function () {
    if ($(this).val() === 'supplier') {
      $('select#price_pool_bill_type').val('bill_ready')
      $('#bill-type-control').hide()
    } else {
      $('#bill-type-control').show()
    }
  })

  $('#price_pool_price_type').change(function () {
    $('#price_pool_price_adder').val('0.0')
    if ($(this).val() === 'F') { // fixed
      App.PricePool.price_type_not_indexed()
      $('#price-adder-control').show()
    } else if ($(this).val() === 'N') { // indexed
      $('label[for="price_pool_price_adder"]').text('Price adder')
      $('#price-index-control').show()
      $('#price-adder-control').show()
    } else { // variable, managed
      App.PricePool.price_type_not_indexed()
      $('#price-adder-control').hide()
    }
  })

  $('#price-popover').click(function () {
    if ($('div.popover:visible').length) {
      $('#price-popover').popover('destroy')
    } else {
      App.PricePool.update_price_index_table()
    }
  })
})
