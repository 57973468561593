/* globals $ Turbo App */
// import { get } from "@rails/request.js"

// App.OpsEnrollments = {

//   refresh_products() {
//     const utility_id = $('select#utility_id').val()
//     const product_segment = $('select#product_segment').val()

//     if (utility_id && product_segment) {
//       let params = new URLSearchParams()
//       params.append('utility_id', utility_id)
//       params.append('product_segment', product_segment)

//       get(`/operations/enrollments/products?${params}`, { responseKind: "turbo-stream" })
//     } else {
//       $("select#product_id").html("<option>Please select</option>")
//     }

//     $('#enrollment_form').hide()
//     $('#no_product').show()
//   }
// }

document.addEventListener('triggerJQuery', function () {
  if (!($('.enrollments').length > 0)) { return }

  // $('select#product_id').change(function () {
  //   const namespace = location.pathname.split('/')[1]

  //   const product_id = $('select#product_id').val()
  //   if (product_id) {
  //     return Turbo.visit(`/${namespace}/enrollments/new?product_id=${product_id}`)
  //   } else {
  //     return Turbo.visit(`/${namespace}/enrollments/new`)
  //   }
  // })

  // $('select#product_segment').change(() => App.OpsEnrollments.refresh_products())

  // $('select#utility_id').change(() => App.OpsEnrollments.refresh_products())

  $('#reject_no_export').click(function () {
    $('form.edit_enrollment').append('<input type="hidden" name="commit" value="Reject_no_export"/>')
    $('form.edit_enrollment').submit()

    return false
  })
})
