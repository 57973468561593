import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    // console.log("operations enrollment controller")
  }

  submit(event) {
    this.formTarget.requestSubmit()
  }

}
