/* globals $ */

$(document).on('click', '.remove_margin', function (event) {
  $(this).prev('input[type=hidden]').val('1')
  $(this).closest('.margin-form').hide()
  return false
})

document.addEventListener('triggerJQuery', function () {
  if (!($('.utility_margins').length > 0)) { return }

  $('.add_margin').click(function () {
    const content = $(this).data('content')
    const newId = (new Date()).getTime()
    const regexp = new RegExp('new_margins', 'g')
    $(this).parent().before(content.replace(regexp, newId))
    return false
  })
})
