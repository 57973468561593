// rails support
import "@hotwired/turbo-rails"
import './controllers'
import './channels'
import 'trix'
import '@rails/actiontext'
require('@rails/activestorage').start()

// Entry point for the build script in your package.json
import './jquery' // must import this way because of hoisting
import 'bootstrap'
import 'peity'
import 'eonasdan-bootstrap-datetimepicker'
import 'bootstrap-toggle'
import 'bootstrap-select'
import 'chartkick/highcharts'
import Highcharts from 'highcharts'
window.Highcharts = Highcharts

import './init'
import './general'
import './direct_upload'
import './costs'
import './cost_profiles'
import './utility_margins'
import './price_pools'
import './quotes'
import './service_requests'
import './choice_customers'
import './call_sessions'
import './customer_leads'
import './operations_enrollments'
import './pool_charges'
import './prices'
import './promotions'
import './utilities'
import './settings'
import './quote_prices'
import './reports'
import './products'
import './campaigns'
import './edi_transactions'
import './brokers'
import './competitor_prices'
import './import_files'

// Following imports have been moved to more local js files where they are used
// import 'dropzone'
// import 'html5sortable'
// import { NexantSyncPoller } from 'nexant_sync'
