/* globals $ */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  changeDeliveryMethod(event) {
    const deliveryMethod = $(event.currentTarget).val()

    if (deliveryMethod == 'email') {
      $('#email_address_section').show()
      $('#postal_address_section').hide()
    } else {
      $('#email_address_section').hide()
      $('#postal_address_section').show()
    }
  }

  closeModal(event) {
    if (event.detail.success) {
      $('#mail_delivery_modal').modal('hide')
    }
  }

}
