/* globals $ */

document.addEventListener('triggerJQuery', function () {
  if (!($('.choice_customers').length > 0)) { return }

  // not currently used
  $('a.show_choice_customer').click(function () {
    const customerId = $(this).data('id')

    $('#choice_customer_modal').modal('show')
    $('#choice_customer_title').html($(this).data('name'))
    $('#choice_customer_results').html("<div style='font-size:1.4em; text-align:center'><span class='glyphicon glyphicon-refresh spinning'></span> Loading Profile</div>")

    $.ajax({
      url: `/choice_customers/${customerId}`,
      success (response) {
        $('#choice_customer_results').html(response)
        $('.peity-bar').peity('bar', { width: 50 })
      },
      error () {
        $('#choice_customer_results').html("<div style='font-size:1.2em; text-align:center'><br><br><i class='fa fa-exclamation-circle fa-lg'></i> Missing data to calculate customer costs<br><br><br></div>")
      }
    })

    return false
  })
})
