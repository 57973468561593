import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["selectControl"]
  static values = {
    url: String,
    param: String
  }

  connect() {
    // console.log("dynamic select connected")
  }

  change(event) {
    // let utilityId = $('form').data('utility-id')
    let params = new URLSearchParams()
    params.append(this.paramValue, event.target.selectedOptions[0].value)
    params.append('target', this.selectControlTarget.id)

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })

    // $('select#product_broker_id').selectpicker('refresh')
  }
}


  // refresh_brokers(channel) {
  //   const utilityId = $('form').data('utility-id')

  //   if (channel) {
  //     $.get(`/utilities/${utilityId}/products/brokers`, `channel=${channel}`, null, "script")
  //   } else {
  //     $("select#product_product_channel").html("<option>Please select</option>")
  //   }
  // }
